import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import IcoVideoYellow from '../../assets/icons/video-icon-yellow.svg';
import IcoqmBlue from '../../assets/icons/question-mark.svg';
import { useUserContext } from '../../context/user-context';
import Hamburger from '../../assets/icons/hamburger.svg';
import IcoLogout from '../../assets/icons/logout.svg';
import IcoJudges from '../../assets/icons/judges.svg';
import { PARTICIPANT, VOTE } from '../../constants';
import Avatar from '../../assets/icons/avatar.svg';
import IcoTeam from '../../assets/icons/team.svg';
import IcoIno from '../../assets/icons/info.svg';
import logo from '../../assets/images/logo-season2.png';
import '../../assets/css/header-style.css';
import IcoFAQ from '../../assets/icons/question-mark-yellow.svg';
import IcoShop from '../../assets/icons/shop.svg';
import IcoSponsor from '../../assets/icons/Partner.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import trophy from '../../assets/icons/trophy.svg';

const HeaderMobile = () => {
    const { user, userType, logout } = useUserContext();

    const handleLogout = () => {
        logout();
        toast('User logout successfully');
    };

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);

    return (
        <header className='py-2 d-lg-none d-block'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-4'>
                        <button
                            className='btn-transparent btn border-0'
                            data-bs-toggle='offcanvas'
                            data-bs-target='#offcanvasExample'
                            aria-controls='offcanvasExample'
                        >
                            <img src={Hamburger} className='img-fluid' alt='Hamburger' />
                        </button>
                    </div>
                    <div className='col-4 text-center'>
                        <Link to='/'>
                            <img src={logo} width='140px' alt='logo' />
                        </Link>
                    </div>
                    <div className='col-4 text-end'>
                        <Link to='/about-us'>
                            <img src={IcoqmBlue} alt='ico' className='img-fluid' />
                        </Link>
                    </div>
                </div>
            </div>

            <div className='offcanvas offcanvas-start' id='offcanvasExample' aria-labelledby='offcanvasExampleLabel'>
                <div className='text-end'>
                    <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close'></button>
                </div>

                {user && userType === PARTICIPANT && (
                    <div className='offcanvas-header'>
                        <div className='row'>
                            <div className='col-3'>
                                <div className='profile-img'>
                                    <img src={Avatar} className='img-fluid' alt='Avatar' />
                                </div>
                            </div>
                            <div className='col-9'>
                                <h5 className='offcanvas-title ms-3'>{user.participant_name}</h5>
                            </div>
                        </div>
                    </div>
                )}

                <div className='offcanvas-body px-0'>
                    <ul className='nav nav-pills flex-column '>
                        <li className='nav-item'>
                            <Link to='/entries' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoVideoYellow} className='img-fluid me-2' alt='IcoVideoYellow' />
                                Entries
                            </Link>
                        </li>
                        <li className='nav-items'>
                            <NavDropdown
                             title={
                                <div className="d-inline-block">
                                    
                                    <img className='img-fluid me-2'
                                        src={trophy} 
                                        width={24}
                                        alt="Highlights"
                                    />
                                        Highlights
                                </div>
                            } 
                             className="basic-nav-dropdown">
                                <NavDropdown.Item eventKey='/winners-2024' to='/winners-2024' as={NavLink} data-bs-dismiss='offcanvas'>Winners 2024</NavDropdown.Item>
                                <Dropdown.Divider />
                                <NavDropdown.Item eventKey='/best-of-2024' to='/best-of-2024' as={NavLink} data-bs-dismiss='offcanvas'>Best of 2024</NavDropdown.Item>
                            </NavDropdown>
                        </li>

                        <li className='nav-item'>
                            <Link to='/judges' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoJudges} className='img-fluid me-2' alt='IcoIno' />
                                Judges
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/merchandise' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoShop} className='img-fluid me-2' alt='IcoIno' />
                                BPL Bazaar
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/sponsor' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoSponsor} className='img-fluid me-2' alt='IcoIno' />
                                Sponsor
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/faqs' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoFAQ} className='img-fluid me-2' alt='IcoIno' />
                                FAQ
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about-us' className='nav-link' data-bs-dismiss='offcanvas'>
                                <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' />
                                About Us
                            </Link>
                        </li>
              
                        {userType === PARTICIPANT && (
                            <li className='nav-item'>
                                <Link to='/my-videos' className='nav-link' data-bs-dismiss='offcanvas'>
                                    <img src={IcoVideoYellow} className='img-fluid me-2' alt='IcoVideoYellow' />
                                    My Videos
                                </Link>
                            </li>
                        )}

                        {userType === PARTICIPANT && (
                            <li className='nav-item'>
                                <Link to='/my-team' className='nav-link' data-bs-dismiss='offcanvas'>
                                    <img src={IcoTeam} className='img-fluid me-2' alt='IcoTeam' />
                                    My Team
                                </Link>
                            </li>
                        )}

                        {/* {userType === PARTICIPANT && (
                            <li className='nav-item'>
                                <Link to='/about-us' className='nav-link' data-bs-dismiss='offcanvas'>
                                    <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' />
                                    About Us
                                </Link>
                            </li>
                        )} */}

                        {userType === PARTICIPANT && (
                            <li className='nav-item'>
                                <Link to='/faqs' className='nav-link' data-bs-dismiss='offcanvas'>
                                    <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' />
                                    FAQ
                                </Link>
                            </li>
                        )}

                        {userType === PARTICIPANT && (
                            <li className='nav-item'>
                                <Link to='/terms-and-conditions' className='nav-link' data-bs-dismiss='offcanvas'>
                                    <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' />
                                    Terms & Conditions
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>

                {[VOTE, PARTICIPANT].includes(userType) && (
                    <div className='offcanvas-footer'>
                        <ul className='nav nav-pills flex-column mt-auto'>
                            <li className='nav-item'>
                                <button className='nav-link text-dark-red' onClick={handleLogout}>
                                    <img src={IcoLogout} className='img-fluid me-2' alt='IcoLogout' />
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </header>
    );
};

export default HeaderMobile;
