import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import React from 'react';

import BannerHomeDesktop from '../../assets/images/banner-coming-soon-season2-desktop.jpg';
import BannerTwoDesktop from '../../assets/images/banner-two-desktop.png';
import BannerTwoMobile from '../../assets/images/banner-two-mobile.png';
import Bhangra from '../../assets/images/banner-coming-soon-season2-mobile.jpg';

import dWinnerBanner1 from '../../assets/images/winners/category-a-position-1.jpg';
import dWinnerBanner2 from '../../assets/images/winners/category-a-position-2.jpg';
import dWinnerBanner3 from '../../assets/images/winners/category-b-position-1.jpg';
import dWinnerBanner4 from '../../assets/images/winners/category-b-position-2.jpg';

import mWinnerBanner1 from '../../assets/images/winners/category-a-position-1-m.jpg';
import mWinnerBanner2 from '../../assets/images/winners/category-a-position-2-m.jpg';
import mWinnerBanner3 from '../../assets/images/winners/category-b-position-1-m.jpg';
import mWinnerBanner4 from '../../assets/images/winners/category-b-position-2-m.jpg';

import dAllWinners from '../../assets/images/winners/all-winners-bpl-2024.jpg';
import mAllWinners from '../../assets/images/winners/all-winners-bpl-2024-m.jpg';

const TopBanner = () => {
    return (
        <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            className='swiper-home'
        >
            <SwiperSlide>
                <div className='middle-sec'>
                    <div className='container'>
                        <div className='text-center d-lg-none d-block'>
                            <img src={Bhangra} alt='img' className='img-fluid w-100 ' />
                        </div>
                        <div className='text-center d-none d-lg-block'>
                            <img src={BannerHomeDesktop} alt='img' className='img-fluid w-100 ' />
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mAllWinners} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dAllWinners} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mWinnerBanner1} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dWinnerBanner1} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mWinnerBanner2} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dWinnerBanner2} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mWinnerBanner3} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dWinnerBanner3} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='container'>
                    <div className='text-center d-lg-none d-block'>
                        <img src={mWinnerBanner4} alt='img' className='img-fluid w-100 ' />
                    </div>
                    <div className='text-center d-none d-lg-block'>
                        <img src={dWinnerBanner4} alt='img' className='img-fluid w-100 ' />
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default TopBanner;
