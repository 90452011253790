import Container from 'react-bootstrap/Container';
import { Link, useLocation, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { toast } from 'react-toastify';
import Nav from 'react-bootstrap/Nav';
import React,  {useState} from 'react';

import { useAuthContext } from '../../context/auth.context';
import { useUserContext } from '../../context/user-context';
import IcoLogout from '../../assets/icons/logout.svg';
import { PARTICIPANT, VOTE } from '../../constants';
import Avatar from '../../assets/icons/avatar.svg';
import IcoShop from '../../assets/icons/shop.svg';
import IcoIno from '../../assets/icons/info.svg';
import logo from '../../assets/images/logo-season2.png';
import '../../assets/css/header-style.css';

const HeaderDesktop = () => {
    const location = useLocation();

    const { handleOpenVote, handleOpenParticipate } = useAuthContext();
    const { user, userType, logout } = useUserContext();
    const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}

    const handleLogout = () => {
        logout();
        toast('User logout successfully');
    };

    return (
        <header className='d-none d-lg-block'>
            <Navbar collapseOnSelect expand='lg' className='bg-transparent nav-desktop'>
                <Container>
                    <Navbar.Brand as={Link} to='/'>
                        <img src={logo} alt='img' width='140px' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='m-auto' activeKey={location.pathname}>
                            <Nav.Link eventKey='/' as={Link} to='/' className='me-2'>
                                Home
                            </Nav.Link>
                            <Nav.Link eventKey='/about-us' as={Link} to='/about-us' className='me-1'>
                                About
                            </Nav.Link>
                            <Nav.Link eventKey='/entries' as={Link} to='/entries' className='me-1'>
                                Entries
                            </Nav.Link>
                            <NavDropdown title="Highlights" className="basic-nav-dropdown" 
                            show={show}
                            onMouseEnter={showDropdown} 
                            onMouseLeave={hideDropdown}
                         
                            >
                                <NavDropdown.Item eventKey='/winners-2024' to='/winners-2024' as={NavLink}>Winners 2024</NavDropdown.Item>
                                <Dropdown.Divider />
                                <NavDropdown.Item eventKey='/best-of-2024' to='/best-of-2024' as={NavLink}>Best of 2024</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey='/judges' as={Link} className='me-1 ' to='/judges'>
                                Judges
                            </Nav.Link>
                            <Nav.Link eventKey='/merchandise' as={Link} className='me-1 ' to='/merchandise'>
                                BPL Bazaar
                            </Nav.Link>
                            <Nav.Link eventKey='/sponsor' as={Link} className='me-1 ' to='/sponsor'>
                                Sponsor
                            </Nav.Link>
                            <Nav.Link eventKey='/faqs' as={Link} className='me-1 ' to='/faqs'>
                                FAQ
                            </Nav.Link>
                            {userType === VOTE && (
                                <button className='nav-link text-dark-red' onClick={handleLogout}>
                                    <img src={IcoLogout} className='img-fluid me-1' alt='IcoLogout' />
                                    Logout
                                </button>
                            )}
                            {userType === PARTICIPANT && (
                                <Nav.Link eventKey='/my-videos' as={Link} className='me-1' to='/my-videos'>
                                    My Videos
                                </Nav.Link>
                            )}
                            {userType === PARTICIPANT && (
                                <Nav.Link eventKey='/my-team' as={Link} className='me-1' to='/my-team'>
                                    My Team
                                </Nav.Link>
                            )}
                        </Nav>
                        <Nav>
                            {!userType && (
                                <Nav.Link
                                    className='btn-theme btn-primary me-3 text-center text-light'
                                    onClick={handleOpenVote}
                                >
                                    Login/Vote
                                </Nav.Link>
                            )}
                            {(userType !== PARTICIPANT || !userType) && (
                                <Nav.Link
                                    className='btn-theme btn-secondary text-center text-light'
                                    onClick={handleOpenParticipate}
                                >
                                    Participate
                                </Nav.Link>
                            )}
                        </Nav>

                        {user && userType === PARTICIPANT && (
                            <Dropdown className='dropdown-profile'>
                                <Dropdown.Toggle variant='transparent' id='dropdown-basic'>
                                    <img src={Avatar} alt='mdo' width='40' height='40' className='rounded-circle' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to='/merchandise'>
                                        <img src={IcoShop} className='img-fluid me-2' alt='IcoIno' /> Merchandise
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/about-us'>
                                        <img src={IcoIno} className='img-fluid me-2' alt='IcoIno' /> About Us
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item as={Link} to='/faqs'>
                                        <img src={IcoFAQ} className='img-fluid me-2' alt='IcoIno' /> FAQ
                                    </Dropdown.Item> */}
                                    <Dropdown.Divider />
                                    <Dropdown.Item className='text-dark-red' onClick={handleLogout}>
                                        <img src={IcoLogout} className='img-fluid me-2 ' alt='IcoIno' /> Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderDesktop;
